import * as React from 'react';
import styled from '@emotion/styled';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from '../../atoms/Typography';
import { CustomLinkProps } from '../../atoms/CustomLink';
import { CheckBoxField } from '../../atoms/CheckboxGroup/CheckboxGroup';
import { Button } from '../../atoms/Button';

export type CookiesSubItem = CustomLinkProps | string;

interface StyledPaperProps {
  backgroundColor?: string;
}

export interface CookiesBannerProps {
  /**
   * Title for the Cookies
   */
  title?: string;
  /**
   * text for the banner
   */
  text?: string;
  /**
   * checkboxes for the cookies
   */
  boxes?: { [name: string]: boolean };
  /**
   * the fields used to generate the checkbox options
   */
  fields?: CheckBoxField[];
  /**
   * handle close of cookie banner
   */
  handleClose?: (title: string) => void | Promise<void>;
}

const StyledPaper = styled(Paper)<StyledPaperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 1rem 1rem 1rem;
  border-radius: 0.25rem;
  color: #fff;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const StyledCheckboxGroup = styled(FormGroup)<{ isMobile: boolean }>`
  display: flex;
  margin-bottom: 2rem;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  & label {
    width: 35%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: inherit;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  color: white;
  &.Mui-checked {
    color: #fff;
  }
`;

const StyledGrid = styled(Grid)<{ isMobile: boolean }>`
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  align-items: center;
  > * {
    width: fit-content;
    &:not(:last-child) {
      margin-right: ${({ isMobile }) => (isMobile ? 'initial' : '2rem')};
    }
    &:not(:first-child) {
      margin-bottom: ${({ isMobile }) => (isMobile ? '1.5rem' : 'initial')};
    }
  }
`;

const defaultBoxes = {
  necessary: true,
  preferences: false,
  statistics: false,
  marketing: false,
};

const fullCookies = { necessary: true, preferences: true, statistics: true, marketing: true };
const checkboxFields = [
  { label: 'Necessary', name: 'necessary' },
  { label: 'Preferences', name: 'preferences' },
  { label: 'Statistics', name: 'statistics' },
  { label: 'Marketing', name: 'marketing' },
];

export const CookiesBanner: React.FC<CookiesBannerProps> = ({
  title = 'This website uses cookies',
  text = 'We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
  boxes = defaultBoxes,
  fields = checkboxFields,
  handleClose,
}) => {
  const theme = useTheme();

  const { primary, neutral } = theme.palette;
  const isMobile = useMediaQuery('(max-width:600px)');
  const [cookie, setCookie] = React.useState(boxes);
  const addCookie = (cookies: { [name: string]: boolean }) => {
    document.cookie = `user=${JSON.stringify(cookies)}; max-age=8640000;}`;
  };

  const handleSubmit = () => {
    addCookie(cookie);
    handleClose && handleClose(title);
  };

  const checkBoxes = fields?.map((box: CheckBoxField) => (
    <FormControlLabel
      key={box.name}
      style={{ marginTop: '0.75rem' }}
      control={
        <StyledCheckbox
          checked={box.name === 'necessary' ? true : Boolean(cookie[box?.name]) ?? false}
          name={box.name}
          onChange={() => setCookie({ ...cookie, [box.name]: !cookie[box?.name] })}
        />
      }
      label={box.label}
    />
  ));
  return (
    <StyledPaper elevation={0} backgroundColor={primary.main}>
      <Grid container direction="row" width="100%">
        <Grid
          item
          container
          paddingLeft={isMobile ? '1.5rem' : '3rem'}
          paddingTop="1rem"
          direction="row"
        >
          <Grid
            item
            xs={10}
            container
            direction="row"
            paddingTop={isMobile ? '.5rem' : '2rem'}
            paddingRight={isMobile ? '1.5rem' : '3rem'}
            flexGrow={1}
          >
            <Typography color={neutral.white100} size="h6">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        paddingTop="1rem"
        paddingLeft={isMobile ? '1.5rem' : '3rem'}
        paddingBottom={isMobile ? '1.5rem' : '3rem'}
        paddingRight={isMobile ? '1.5rem' : '3rem'}
      >
        <Typography size="p" color={theme.palette.neutral.white100} fontWeight={100}>
          {text}
        </Typography>

        <StyledCheckboxGroup isMobile={isMobile}>{checkBoxes}</StyledCheckboxGroup>
        <StyledGrid isMobile={isMobile} container flexDirection={isMobile ? 'column' : 'row'}>
          <Button
            fullWidth={!!isMobile}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleSubmit}
            label="Allow selection"
            style={{ order: isMobile ? 99 : 0, color: theme.palette.primary.main }}
          />
          <Button
            color="inherit"
            type="submit"
            onClick={() => setCookie(fullCookies)}
            label="Allow all cookies"
          />
          <Button
            color="inherit"
            type="submit"
            onClick={() => setCookie(defaultBoxes)}
            label="Use necessary cookies only"
          />
        </StyledGrid>
      </Grid>
    </StyledPaper>
  );
};
