import { useTheme } from '@material-ui/core';
import { Link, LinkProps } from 'react-scroll';
import { Typography, TypographyProps } from '../Typography';

export interface CustomScrollLinkProps extends TypographyProps {
  label: string | number;
  linkColor?: string;
  path?: LinkProps['to'];
  offset?: LinkProps['offset'];
}

export const CustomScrollLink: React.FC<CustomScrollLinkProps> = ({
  label,
  path = '',
  offset = -85,
  linkColor,
  onClick,
  ...rest
}) => {
  const theme = useTheme();
  const color = linkColor ?? theme.palette.neutral.charcoal100;
  return (
    <Link to={path} spy smooth offset={offset} duration={500} className="pointer">
      <Typography size="h6" component="h6" color={color} {...rest}>
        {label}
      </Typography>
    </Link>
  );
};
