import React from 'react';
import { Helmet } from 'react-helmet-async';

const defaultMeta = {
  title: 'Tezos Hackathons',
  description:
    'Find out about upcoming hackathons and get support organizing your own event. Join the growing global community shaping the future of Tezos!',
  url: 'http://hackathons.tzconnect.com/',
  page: 'main',
};

const ShareImages = {
  main: 'https://hackathons.tzconnect.com/assets/social/landing-page-social.png',
  funding: 'https://hackathons.tzconnect.com/assets/social/funding-application-page-social.png',
  fundingSuccess:
    'https://hackathons.tzconnect.com/assets/social/funding-application-page-social.png',
  organizer: 'https://hackathons.tzconnect.com/assets/social/organizer-page-social.png',
  participant: 'https://hackathons.tzconnect.com/assets/social/participant-page-social.png',
  partner: 'https://hackathons.tzconnect.com/assets/social/partnership-page-social.png',
  partnerSuccess: 'https://hackathons.tzconnect.com/assets/social/partnership-page-social.png',
};
export const Urls = {
  main: 'http://hackathons.tzconnect.com/',
  funding: 'http://hackathons.tzconnect.com/funding-application',
  fundingSuccess: 'http://hackathons.tzconnect.com/funding-application-submitted',
  organizer: 'http://hackathons.tzconnect.com/organizer-starter-kit',
  participant: 'http://hackathons.tzconnect.com/participant-resources',
  partner: 'http://hackathons.tzconnect.com/partnership-inquiry',
  partnerSuccess: 'http://hackathons.tzconnect.com/partnership-inquiry-submitted',
};

export interface MetaTagsProps {
  title?: string;
  description?: string;
  page: keyof typeof Urls;
  url?: string;
}
export const getPageMetadata = (metaData: MetaTagsProps): MetaTagsProps => {
  const { title, description, page } = metaData;
  const defaultData = defaultMeta;
  return {
    title: title || defaultData?.title,
    description: description || defaultData?.description,
    url: Urls[page] || defaultData?.url,
    page: page || defaultData?.page,
  };
};

export const MetaTags: React.FC<MetaTagsProps> = (props) => {
  const meta = getPageMetadata(props);
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <link rel="canonical" href={meta.url} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={ShareImages[meta.page]} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="hackathons.tzconnect.com" />
      <meta property="twitter:url" content={meta.url} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={ShareImages[meta.page]} />
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};
