import React from 'react';
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  Theme,
  useTheme,
} from '@material-ui/core';
import styled from '@emotion/styled';

interface StyledButtonProps {
  bordercolor?: string;
  theme: Theme;
  /**
   * Disable Hover effects
   */
  disableHover?: boolean;
}

const StyledButton = styled(MaterialButton)<StyledButtonProps>`
  padding: 0.2em 1.2em;
  box-shadow: none;
  border-radius: 0;
  font-weight: 500;
  line-height: 1.5;
  text-transform: initial;

  &.MuiButton-containedSizeMedium {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 2.75rem;
  }

  &.MuiButton-containedPrimary {
    &:hover {
      background-color: rgba(1, 102, 255, 0.87);
      box-shadow: none;
    }
  }

  &.MuiButton-containedSecondary {
    background-color: ${({ theme }) => theme.palette.background.paper};
    &:hover {
      color: rgba(1, 102, 255, 0.87);
      background-color: ${({ theme }) => theme.palette.background.paper};
      box-shadow: none;
    }
  }

  &.MuiButton-textSizeMedium {
    font-size: 1rem;
  }

  &.MuiButton-textSizeLarge {
    font-size: 1.1rem;
  }

  &.MuiButton-text {
    padding: 0;
    border-bottom: solid 1.5px;
    &:hover {
      background-color: ${({ theme, disableHover }) =>
        disableHover ? 'transparent' : theme.palette.secondary.main};
    }
  }
`;

export interface ButtonProps extends MaterialButtonProps {
  /**
   * Button contents
   */
  label: string;
  /**
   * Disable Hover effects
   */
  disableHover?: boolean;
  /**
   * Button contains icon in LeftSide
   */

  icon?: string | React.ReactNode;
  /**
   * Button contains icon in RightSide
   */
  iconPosition?: 'left' | 'right';
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  label,
  icon,
  iconPosition = 'right',
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledButton
      {...props}
      theme={theme}
      variant={variant}
      startIcon={iconPosition === 'left' ? icon : null}
      endIcon={iconPosition === 'right' ? icon : null}
    >
      {label}
    </StyledButton>
  );
};
