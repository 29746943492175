import React, { useCallback, useEffect, useState } from 'react';
import Headroom from 'react-headroom';
import { AnimationProps, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { Header } from '../../design-system/molecules/Header';
import { Footer } from '../../design-system/molecules/Footer';
import { HeaderMenuItem } from '../../design-system/molecules/Header/Header';
import { CookiesBanner } from '../../design-system/molecules/CookiesBanner';
import { MetaTags } from '../../design-system/molecules/MetaTags';
import { Urls } from '../../design-system/molecules/MetaTags/MetaTags';
import { routes } from '../../router/routes';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CustomHeader = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    &.headroom--unfixed {
      position: absolute;
      transform: translateY(0);
    }
    &.headroom--scrolled {
      transition: transform 200ms ease-in-out;
    }
    &.headroom--unpinned {
      position: fixed;
      transform: translateY(-100%);
    }
    &.headroom--pinned {
      position: fixed;
      transform: translateY(0%);
    }
  }
`;

interface MainPageProps {
  title?: string;
  description?: string;
  heroSize: number;
  page?: keyof typeof Urls;
}

const pageVariants: AnimationProps['variants'] = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: { duration: 0.35 },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const HeaderMenuItems: HeaderMenuItem[] = [
  {
    label: 'Organizers',
    url: '/organizer',
  },
  {
    label: 'Participants',
    url: '/participant',
  },
  {
    label: 'Partners',
    url: '/partnership-application',
  },
];

const FooterLinkGroups = [
  {
    title: 'Made by ',
    image: '/assets/images/tz-connect-combination-mark-0166ff.svg',
    links: [
      {
        label: 'We’re a Berlin-based team dedicated to advancing the Tezos ecosystem.',
      },
    ],
  },
  {
    title: 'Tezos Ecosystem',
    links: [
      {
        label: 'Tezos.com',
        href: 'https://tezos.com',
      },
      {
        label: 'Tezos Foundation',
        href: 'https://tezos.foundation',
      },
      {
        label: 'Tezos Developer Portal',
        href: 'https://tezos.com/developer-portal',
      },
      {
        label: 'Tezos Agora',
        href: 'https://tezosagora.org',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        label: 'Imprint',
        href: 'https://tzconnect.com/imprint',
      },
      {
        label: 'Privacy Policy',
        href: 'https://tzconnect.com/privacy-policy',
      },
    ],
  },
];
const defaultPage = 'main';

export const MainPage: React.FC<MainPageProps> = ({
  title,
  children,
  description,
  heroSize,
  page = defaultPage,
}) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const hasCookie = document.cookie.match(/^(.*;)?\s*user\s*=\s*[^;]+(.*)?$/);
  const [cookie, setCookie] = useState(false);
  const location = useLocation();
  const path = routes.map((p) => p.path);

  const headerItems = React.useMemo(() => {
    if (location.pathname === '/') {
      return [
        {
          label: 'Featured Hackathons',
          url: 'featured-hackathons',
        },
        ...HeaderMenuItems,
      ];
    }
    return [
      {
        label: 'Featured Hackathons',
        url: '/#featured-hackathons',
      },
      ...HeaderMenuItems,
    ];
  }, [location.pathname]);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    const checkHeaderStatus = () => {
      mobileMenuIsOpen || scrollHeight > heroSize ? setHeaderColor(true) : setHeaderColor(false);
    };
    const onScroll = (e: any) => {
      if (!mobileMenuIsOpen) {
        setScrollHeight(e.target.documentElement.scrollTop);
        checkHeaderStatus();
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [mobileMenuIsOpen, scrollHeight, heroSize, headerColor]);

  return (
    <PageContainer>
      <MetaTags title={title} description={description} page={page} />
      <CustomHeader
        downTolerance={80}
        disable={mobileMenuIsOpen}
        disableInlineStyles
        className={mobileMenuIsOpen ? 'disabled' : undefined}
      >
        <Header
          title="Tezos Hackathons"
          menuItems={headerItems}
          mobileMenuClickHandler={toggleMobileMenu}
          hasStaticBackgroundColor={headerColor}
        />
      </CustomHeader>
      <main>
        <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
          {children}
        </motion.div>
      </main>
      {!hasCookie && <CookiesBanner handleClose={() => setCookie(!cookie)} />}
      {path.indexOf(location.pathname) > -1 && <Footer linkGroups={FooterLinkGroups} />}
    </PageContainer>
  );
};
