import React from 'react';
import styled from '@emotion/styled';
import { Paper, Grid, Container, GridSize, useTheme, Link } from '@material-ui/core';
import { Typography } from '../../atoms/Typography';

const FooterStyled = styled.footer`
  margin-top: auto;
`;

const FooterContainer = styled(Container)`
  width: 100%;
  display: flex;

  flex-shrink: 0;
  position: relative;
  top: auto;
  bottom: 0;

  a:hover {
    color: initial;
  }
`;

const PaperWrapper = styled(Paper)`
  width: 100%;
  padding: 0 2rem 2rem 2rem;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 100;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

interface FooterLink {
  label: string;
  href?: string;
  handleLinkClick?: () => void | Promise<void>;
}

interface FooterLinkGroup {
  /**
   * Title for the group
   */
  title: string;
  /**
   * Image in title
   */
  image?: string;
  /**
   * Links to show in the group
   */
  links: FooterLink[];
}
export interface FooterProps {
  /**
   * All the links to show in the footer
   */
  linkGroups: FooterLinkGroup[];
}

export const Footer: React.FC<FooterProps> = ({ linkGroups }) => {
  const theme = useTheme();
  return (
    <FooterStyled>
      <FooterContainer disableGutters maxWidth={false}>
        <PaperWrapper elevation={0}>
          <Grid container direction="row">
            {linkGroups.map((group, index) => (
              <Grid
                container
                item
                xs={12}
                md={Math.floor(12 / linkGroups.length) as GridSize}
                direction="column"
                alignItems="center"
                key={`${group.title}-${index}`}
              >
                <Grid item width="12rem" marginTop="4rem">
                  <Grid item>
                    {group.image ? (
                      <Typography color="primary" fontWeight="bold" display="inline-block">
                        {group.title}
                        <img alt={group.title} src={group.image} height="11" />
                      </Typography>
                    ) : (
                      <Typography color="primary" fontWeight="bold">
                        {group.title}
                      </Typography>
                    )}
                  </Grid>
                  {group.links.map((link, idx) =>
                    link.href ? (
                      <Grid
                        item
                        key={`${link.label}-${idx}`}
                        onClick={link.handleLinkClick}
                        marginTop="12px"
                      >
                        <StyledLink
                          color={theme.palette.neutral.charcoal65}
                          href={link.href}
                          target="_blank"
                        >
                          {link.label}
                        </StyledLink>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        key={`${link.label}-${idx}`}
                        onClick={link.handleLinkClick}
                        marginTop="12px"
                      >
                        <Typography
                          className="pointer"
                          component="a"
                          color={theme.palette.neutral.charcoal65}
                        >
                          {link.label}
                        </Typography>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </PaperWrapper>
      </FooterContainer>
    </FooterStyled>
  );
};
