import { CustomRouterLink, CustomRouterLinkProps } from './CustomRouterLink';
import { CustomScrollLink, CustomScrollLinkProps } from './CustomScrollLink';

export interface CustomLinkProps {
  type: 'router' | 'scroll';
  linkProps: CustomRouterLinkProps | CustomScrollLinkProps;
  className?: string;
}

export const CustomLink: React.FC<CustomLinkProps> = ({ type, linkProps, className }) =>
  type === 'router' ? (
    <CustomRouterLink {...(linkProps as CustomRouterLinkProps)} className={className} />
  ) : (
    <CustomScrollLink {...(linkProps as CustomScrollLinkProps)} className={className} />
  );
