import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  AppBar,
  Drawer,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CustomLink } from '../../atoms/CustomLink/CustomLink';

interface HeaderDesignProps {
  theme: Theme;
}

const CustomGridItem = styled(Grid)<HeaderDesignProps>`
  padding-left: ${({ theme }) => theme.spacing(5)};
  ${({ theme }) => `${theme.breakpoints.up('md')} {
    padding-left: ${theme.spacing(7.5)};
  }`}
`;

const CustomToolbar = styled(Toolbar)<HeaderDesignProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: fit-content !important;
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => `${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(2.5)} ${theme.spacing(5)};
  }`}
`;

const FullSizeDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 100%;
    height: 100vh;
  }
`;

const DrawerHeader = styled.div<HeaderDesignProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: right;
`;

const CustomListItem = styled(ListItem)<HeaderDesignProps>`
  padding: 0 ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)};
  &:hover {
    background-color: transparent;
  }
`;

interface IconButtonProps {
  isWhite?: boolean;
}
const CustomIconButton = styled(IconButton)<IconButtonProps>`
  padding: 0;
  width: 25.5px;
  filter: ${({ isWhite }) => (isWhite ? 'invert(100%)' : undefined)};
  &:hover {
    background-color: transparent;
  }
`;

export interface HeaderMenuItem {
  label: string;
  url: string;
}

export interface HeaderProps {
  title: string;
  menuItems: HeaderMenuItem[];
  mobileMenuClickHandler?: () => void;
  hasStaticBackgroundColor: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  menuItems,
  mobileMenuClickHandler,
  hasStaticBackgroundColor = false,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [bgColor, setBgColor] = React.useState(theme.palette.neutral.white08);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleOpen = () => {
    setOpenMenu(true);
    if (mobileMenuClickHandler) mobileMenuClickHandler();
  };
  const handleClose = () => {
    setOpenMenu(false);
    if (mobileMenuClickHandler) mobileMenuClickHandler();
  };
  const pathName = useLocation().pathname;

  const links = React.useMemo(() => {
    const OuterContainer = isMobile ? CustomListItem : CustomGridItem;
    return menuItems.map((item) => (
      <OuterContainer item key={`${item.label}-${item.url}`} theme={theme}>
        <CustomLink
          type={item.url.startsWith('/') ? 'router' : 'scroll'}
          linkProps={{
            path: item.url.startsWith('/') ? undefined : item.url,
            onClick: item.url.startsWith('/') ? () => history.push(item.url) : undefined,
            label: item.label,
            linkColor:
              !hasStaticBackgroundColor && !isMobile ? theme.palette.neutral.white100 : undefined,
          }}
          className={pathName === item.url ? 'active' : undefined}
        />
      </OuterContainer>
    ));
  }, [hasStaticBackgroundColor, menuItems, pathName, theme, isMobile]);

  const DesktopMenu = React.useCallback(() => {
    return (
      <Grid container item alignItems="center" justifyContent="flex-end" md={10}>
        {links}
      </Grid>
    );
  }, [links]);

  const MobileMenu = React.useCallback(() => {
    return (
      <>
        <Grid container item justifyContent="flex-end" xs={3}>
          <CustomIconButton
            onClick={handleOpen}
            aria-label="menu"
            isWhite={!hasStaticBackgroundColor ? true : undefined}
          >
            <img src="/assets/images/hamburger.svg" alt="open menu" />
          </CustomIconButton>
        </Grid>
        <Fade in={openMenu}>
          <FullSizeDrawer variant="persistent" anchor="right" open={openMenu}>
            <DrawerHeader theme={theme}>
              <CustomIconButton onClick={handleClose} aria-label="closeMenu">
                <img src="/assets/images/close.svg" alt="close menu" />
              </CustomIconButton>
            </DrawerHeader>
            <List>{links}</List>
          </FullSizeDrawer>
        </Fade>
      </>
    );
  }, [hasStaticBackgroundColor, openMenu, theme, links]);

  // color: ${({ theme }) => theme.palette.text.primary};

  useEffect(() => {
    hasStaticBackgroundColor || openMenu
      ? setBgColor(theme.palette.neutral.white100)
      : setBgColor(theme.palette.neutral.white08);
  }, [hasStaticBackgroundColor, openMenu]);

  const headerLinkProps = React.useMemo(() => {
    return {
      onClick: () => history.push('/'),
      linkColor: !hasStaticBackgroundColor ? theme.palette.neutral.white100 : undefined,
      component: 'h1',
      size: 'h5',
      sx: { lineHeight: { xs: 1, md: 1.3 } },
      label: title,
    };
  }, [hasStaticBackgroundColor, theme.palette.neutral.white100, title]);

  return (
    <AppBar
      position={!isMobile ? 'relative' : 'sticky'}
      sx={{
        backgroundColor: bgColor,
        top: 0,
        backdropFilter: `${bgColor === theme.palette.neutral.white08 ? 'blur(5px)' : undefined}`,
      }}
      elevation={0}
    >
      <CustomToolbar theme={theme}>
        <Grid container alignItems="center">
          <Grid item aria-hidden="true" xs={9} md={2}>
            <CustomLink type="router" linkProps={headerLinkProps} />
          </Grid>
          {isMobile ? <MobileMenu /> : <DesktopMenu />}
        </Grid>
      </CustomToolbar>
    </AppBar>
  );
};
