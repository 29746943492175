import sanitizeHtml from 'sanitize-html';
import styled from '@emotion/styled';
import {
  Container,
  Grid,
  Paper,
  PaperProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { resourceUsage } from 'process';
import React, { useEffect, useRef } from 'react';
import { Button, ButtonProps } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';

const DEFAULT_ACTION_GROUP_SIZE = 3;

interface HeroWrapperProps {
  /**
   * Background image to show
   */
  backgroundImage?: string;
  /**
   * Background color to show
   */
  backgroundColor?: string;
  /**
   * Background gradient to show
   */
  backgroundGradient?: string;
  /**
   * Background Position
   */
  backgroundPosition?: string;
  /**
   * Background image Size
   */
  backgroundImageSize?: string;
  /**
   * Font color for dark background
   */
  fontColor?: string;
  /**
   * To show dotted images
   */
  hasExtraBackgroundImage?: boolean;
  /**
   * If there are 2 dotted images
   */
  isDoubleExtraBackgroundImage?: boolean;
  /**
   * Position of dotted images
   */
  extraBackgroundImagePosition?: 'left bottom' | 'right bottom';
  /**
   * Theme for sizing
   */
  theme?: Theme;
  /**
   * Text position
   */
  textPosition?: 'center' | 'left';

  fullWidth?: boolean;
}

export interface HeroProps extends HeroWrapperProps, PaperProps {
  /**
   * Title text
   */
  title: string;
  /**
   * Subtitle text
   */
  subtitle?: string | string[];
  titleFontSize?: string;
  subtitleFontSize?: string;
  /**
   * Number of buttons to show in a row (Default 3)
   */
  actionGroupSize?: number;
  /**
   * List of buttons with actions
   */
  actions?: ButtonProps[];

  getHeroHeight?: (args: number) => void;
}

const HeroWrapper = styled(Paper)<HeroWrapperProps>`
  padding: 8% 0;
  background-image: ${({ backgroundImage, backgroundGradient }) =>
    `url(${backgroundImage}) , ${backgroundGradient}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  background-position: ${({ backgroundPosition }) => backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-size: ${({ backgroundImageSize, backgroundGradient }) =>
    `${backgroundImageSize} ${backgroundGradient ? ', contain' : null}`};
  box-shadow: none;
  border-radius: 0;
  position: relative;

  ${({ theme, backgroundGradient }) => `${theme.breakpoints.down('sm')} {
    background-image: ${backgroundGradient};
    background-size: contain;
    padding: 30% 0;

    &:before,
    &:after {
      background-size: contain;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.between('sm', 'md')} {
    padding-top: 25%;
    padding-bottom: 25%;
  }`}

  ${({ theme }) => `${theme.breakpoints.up('lg')} {
      .MuiContainer-root > .MuiGrid-container {
        // max-width: max-content;
        // &:not(.leftContainer){
        //   margin: auto;
        // }
        max-width: ${theme.spacing(101)};
        margin: auto;
      }
  }`}


  ${({ hasExtraBackgroundImage, isDoubleExtraBackgroundImage, extraBackgroundImagePosition }) =>
    hasExtraBackgroundImage &&
    `
    &:before,
    &:after {
      content: '';
      @extend %extra-image;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(/assets/images/dotted-wave-white.svg);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: 567px;
      display: block;
    }
    &:before {
      transform: rotate(180deg);
      display: ${isDoubleExtraBackgroundImage ? 'block' : 'none'};
    }

    ${
      extraBackgroundImagePosition === 'right bottom' &&
      `
      &:after {
        transform: rotateY(180deg);
      }
      &:before {
        transform: rotateX(180deg);
      }
  `
    }

  `}
`;

const TextWrapper = styled.div<HeroWrapperProps>`
  display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  & .subtitle {
    display: flex;
    flex-direction: column;
    &-inner {
      flex-grow: 1;
    }
    .subtitle-inner + .subtitle-inner {
      padding-top: 2rem;
    }

    a {
      color: ${({ fontColor, theme }) => fontColor || theme.palette.neutral.white100};
    }
  }
  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    .subtitle h5{
      font-size: 1rem;
    }
  }`}
`;

export const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  actions = [],
  actionGroupSize = DEFAULT_ACTION_GROUP_SIZE,
  hasExtraBackgroundImage = true,
  isDoubleExtraBackgroundImage = true,
  textPosition,
  titleFontSize,
  subtitleFontSize,
  getHeroHeight,
  ...rest
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const innerActions = actions.reduce((acc: ButtonProps[][], item: ButtonProps, i) => {
    const id = Math.floor(i / actionGroupSize);
    const emptyArray: ButtonProps[] = [];
    acc[id] = emptyArray.concat(acc[id] || [], item);
    return acc;
  }, []);
  const heroRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    function handleResize() {
      if (heroRef.current && !!getHeroHeight) {
        getHeroHeight(heroRef.current.clientHeight);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeroWrapper
      {...rest}
      theme={theme}
      ref={heroRef}
      hasExtraBackgroundImage={hasExtraBackgroundImage}
      isDoubleExtraBackgroundImage={isDoubleExtraBackgroundImage}
    >
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: '1' }}>
        <Grid
          container
          direction="column"
          flexDirection="column"
          className={textPosition && textPosition === 'left' ? 'leftContainer' : undefined}
        >
          <Grid item md={6}>
            <TextWrapper
              theme={theme}
              fullWidth={(textPosition && textPosition === 'left') || false}
              {...rest}
            >
              <Typography
                component="h1"
                size={`${titleFontSize || 'h1'}`}
                color="inherit"
                gutterBottom
              >
                {title}
              </Typography>
              {subtitle && (
                <div className="subtitle">
                  {typeof subtitle === 'string' ? (
                    <Typography
                      size={`${subtitleFontSize || 'h5'}`}
                      component="h5"
                      color="inherit"
                      paragraph
                      className="subtitle-inner"
                      fontWeight="100"
                      sx={{ marginBottom: 0 }}
                    >
                      {subtitle}
                    </Typography>
                  ) : (
                    subtitle.map((paragraph, i) => (
                      <Typography
                        key={`${paragraph.substring(0, 2)}_${i}`}
                        size={`${subtitleFontSize || 'h5'}`}
                        component="h5"
                        color="inherit"
                        paragraph
                        className="subtitle-inner"
                        fontWeight="100"
                        sx={{ marginBottom: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(paragraph, {
                            allowedTags: ['a', 'br'],
                            allowedAttributes: {
                              a: ['href', 'class', 'target', 'rel'],
                              code: ['class'],
                            },
                          }),
                        }}
                      />
                    ))
                  )}
                </div>
              )}
            </TextWrapper>
          </Grid>
          {innerActions.length > 0 && (
            <Grid item sx={{ marginTop: 6 }}>
              {innerActions.map((list) => {
                const key = list.map((item) => item.label).join('-');
                return (
                  <Grid
                    container
                    key={key}
                    spacing={2}
                    mb="1rem"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={isMobile ? 'column' : 'row'}
                  >
                    {list.map((item, index) => (
                      <Grid
                        item
                        key={`${item.label}-${index}`}
                        style={{ width: isMobile ? '100%' : 'initial' }}
                      >
                        <Button
                          {...item}
                          variant="contained"
                          size="medium"
                          color="secondary"
                          sx={{
                            width: isMobile ? '100%' : '10rem',
                            height: '2.75rem',
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Container>
    </HeroWrapper>
  );
};
