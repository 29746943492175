import styled from '@emotion/styled';
import { Theme, useTheme } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import { Typography, TypographyProps } from '../Typography';

export interface CustomRouterLinkProps extends TypographyProps {
  label: string | number;
  path?: LinkProps['to'];
  linkColor?: string;
  disableUnderline?: boolean;
  onClick?: () => void | Promise<void>;
  fontColor?: string;
}
interface StyledLinkProps {
  color?: string;
  disableUnderline?: boolean;
  theme: Theme;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color, theme }) => color ?? theme.palette.text.primary};
  &.active,
  &:hover {
    color: ${({ color, theme }) => color ?? theme.palette.primary.main};
  }
  ${({ theme }) => `${theme.breakpoints.up('sm')} {
    font-size: ${theme.spacing(2)};
  }`}
`;

export const CustomRouterLink: React.FC<CustomRouterLinkProps> = ({
  label,
  path = '',
  linkColor,
  disableUnderline,
  onClick,
  className,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledLink
      theme={theme}
      color={linkColor}
      disableUnderline
      className={className}
      component={(props: unknown) => (
        <Typography size="h6" component="h6" {...rest} {...props}>
          {label}
        </Typography>
      )}
      to={path}
      onClick={onClick}
    />
  );
};
